import React, { useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlipMove from "react-flip-move";
import {
  innerWidth,
  lightPurple,
  transHover,
  screen,
} from "../components/variables";
import { toSlug, formatDate, reduceWords } from "../helpers";
import {
  IconCircleDarkPurpleMd,
  IconCircleLightPurpleMd,
  IconCircleCyanMd,
} from "../components/circles";
import { motion } from "framer-motion";
import ButtonUnderlined from "../components/buttons/button-underlined";
import IconArrowSharpDown from "../images/svg/arrow-sharp-down.svg";
import NewsletterForm from "../components/newsletter-form/section";

const Wrapper = styled.div`
  overflow: hidden;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .header {
    .heading {
      font-weight: 900;
      line-height: 1.22;
      font-size: 2rem;
      @media ${screen.xxsmall} {
        font-size: 3.5rem;
      }
      @media ${screen.small} {
        font-size: 5rem;
        line-height: 1.09;
      }
    }

    .flex-row {
      display: none;
      @media ${screen.xxsmall} {
        display: flex;
        flex-direction: column;
        margin: 42px 0 0 0;
      }
      @media ${screen.xsmall} {
        flex-direction: row;
        align-items: center;
      }

      .col {
        &--img {
          @media ${screen.xsmall} {
            width: 60%;
            padding: 0 70px 0 0;
          }

          img {
            cursor: pointer;
          }
        }

        &--text {
          @media ${screen.xxsmall} {
            margin: 26px 0 52px 0;
          }
          @media ${screen.xsmall} {
            width: 40%;
            margin: 0;
          }

          .title {
            cursor: pointer;
            font-weight: 700;
            line-height: 1.4;
            font-size: 1.52rem;
            @media ${screen.small} {
              font-size: 1.55rem;
            }
          }

          .date-tags {
            font-weight: 700;
            margin: 12px 0 13px 0;
            font-size: 1.03rem;
            @media ${screen.small} {
              font-size: 1.07rem;
            }

            .pipe-symbol {
              color: ${lightPurple};
              margin: 0 10px;
            }
          }

          .description {
            margin: 0 0 26px 0;
            padding: 0 18px 0 0;
            font-size: 1.02rem;
            max-width: 650px;
            @media ${screen.small} {
              font-size: 1.06rem;
            }
          }
        }
      }
    }
  }

  #filters {
    margin: 38px 0 0 0;
    display: flex;
    align-items: flex-start;
    @media ${screen.xxsmall} {
      margin: 70px 0 0 0;
    }

    .btns {
      background: #e8f9fd;
      border-radius: 30px;
      padding: 16px 22px;
      cursor: pointer;
      position: relative;
      min-width: 274px;
      @media ${screen.xxsmall} {
        padding: 18px 26px;
        min-width: 295px;
      }

      .btn {
        display: flex;
        align-items: center;

        &__label {
          letter-spacing: 2px;
          font-weight: 900;
          font-size: 1.06rem;
          @media ${screen.xxsmall} {
            font-size: 1.08rem;
          }
          @media ${screen.small} {
            font-size: 1.1rem;
          }
        }

        &__arrow {
          width: 14px;
          position: absolute;
          right: 22px;
          top: calc(50% + 3px);
          transform: translateY(-50%);
          @media ${screen.xxsmall} {
            width: 15px;
            right: 26px;
          }
        }
      }

      &--category {
        position: relative;
        z-index: ${(props) => (props.isCategory ? 31 : 2)};

        .option-list {
          background: #e8f9fd;
          position: absolute;
          top: -1px;
          left: -1px;
          border-radius: 30px;
          overflow: hidden;
          padding: 16px 0 8px 0;
          box-shadow: 0 5px 12px -2px rgba(0, 0, 0, 0.1);
          min-width: 100%;
          @media ${screen.xxsmall} {
            padding: 18px 0 9px 0;
          }

          &__each {
            font-size: 1.07rem;
            line-height: 1.4;
            padding: 0 22px 16px 22px;
            transition: ${transHover};
            @media ${screen.xxsmall} {
              white-space: pre;
              padding: 0 30px 18px 30px;
            }

            &--selected {
              color: ${lightPurple};
            }
          }

          .close-arrow {
            position: absolute;
            width: 15px;
            top: 18px;
            right: 22px;
            opacity: 0.3;
            display: none;
            @media ${screen.xxsmall} {
              top: 22px;
              right: 30px;
            }
          }
        }
      }
    }
  }

  .blog-list-subwrapper {
    position: relative;

    .blog-list {
      display: flex;
      flex-wrap: wrap;
      margin: 48px 0;

      @media ${screen.xxsmall} {
        margin: 70px -13px;
      }
      @media ${screen.medium} {
        max-width: 1212px;
        margin: 70px 0;
      }

      &__each {
        margin: 0 0 64px 0;
        display: flex;
        flex-direction: column;
        @media ${screen.xxsmall} {
          margin: 0 13px 64px 13px;
          width: calc(50% - 26px);
        }
        @media ${screen.small} {
          width: calc(33.33% - 26px);
        }
        @media ${screen.medium} {
          margin: 0 22px 74px 0;
          width: calc(33.33% - 22px);
        }

        .featured-img {
          cursor: pointer;
        }

        .title {
          cursor: pointer;
          font-weight: 700;
          line-height: 1.4;
          margin: 23px 0 0 0;
          font-size: 1.25rem;
          @media ${screen.xxsmall} {
            font-size: 1.52rem;
          }
          @media ${screen.small} {
            font-size: 1.55rem;
          }
        }

        .date-tags {
          font-weight: 700;
          margin: 12px 0 13px 0;
          font-size: 1.02rem;
          line-height: 1.58;
          @media ${screen.xxsmall} {
            font-size: 1.03rem;
          }
          @media ${screen.small} {
            font-size: 1.07rem;
          }

          .pipe-symbol {
            color: ${lightPurple};
            margin: 0 10px;
          }
        }

        .description {
          margin: 0 0 26px 0;
          padding: 0 18px 0 0;
          font-size: 1.02rem;
          @media ${screen.small} {
            font-size: 1.06rem;
          }
        }

        .link {
          margin: auto 0 0 0;
        }
      }
    }

    .icon-circle {
      display: none;
      @media ${screen.medium} {
        display: block;
      }

      &--light-purple {
        top: 664px;
        right: -80px;
        width: 115px;
      }

      &--dark-purple {
        top: 830px;
        right: -103px;
        width: 51px;
      }

      &--cyan {
        top: 150px;
        right: -180px;
        width: 150px;
      }
    }
  }
`;

const BlogsPage = ({ data }) => {
  const categories = data.categories.edges
    .map((item) => item.node.data.title.text)
    .sort();

  const blogsByDate = data.blogs.edges
    .map((item) => {
      return {
        node: {
          // use edit published from prismic if provided
          display_date: item.node.data.edit_published_date
            ? formatDate(item.node.data.edit_published_date)
            : formatDate(item.node.first_publication_date),
          // use edit published from prismic if provided to sort blog list
          // reason to use this is to customize published date display for older blogs
          published_date: item.node.data.edit_published_date
            ? Number(item.node.data.edit_published_date.split("-").join(""))
            : Number(
                item.node.first_publication_date
                  .slice(0, 10)
                  .split("-")
                  .join("")
              ),
          data: item.node.data,
          id: item.node.id,
        },
      };
    })
    .sort((a, b) => b.node.published_date - a.node.published_date);

  const latestBlog = blogsByDate[0];

  const sortedBlogs = blogsByDate;

  // **** START FILTERS

  const [category, setCategory] = useState("");
  const [isCategory, setIsCategory] = useState(false);

  const selectCategory = (val) => {
    setCategory(val);
  };

  const toggleCategory = () => {
    setIsCategory(!isCategory);
  };

  const filteredBlogs =
    category === "All" || category === ""
      ? sortedBlogs
      : sortedBlogs.filter((item) => {
          return item.node.data.categories.some(
            (cat) => cat.category.document.data.title.text === category
          );
        });

  // **** END FILTERS

  const pageURL = `https://www.k9swim.com.au/blog/`;

  useEffect(() => {
    const userClick = (e) => {
      if (
        e.target.className === "inner-wrapper" ||
        e.target.className === "blog-list-subwrapper" ||
        e.target.className === "blog-list" ||
        e.target.className === ""
      ) {
        setIsCategory(false);
      }
    };

    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, [isCategory]);

  const excerptFallback = "Continue reading by click the button below:";

  return (
    <Layout isBlogPage noCirclesOnMobileFooter>
      <SEO
        title={"News & Media"}
        description={"Read the latest articles"}
        image={latestBlog.node.data.featured_image.thumbnails.cropped.url}
        url={pageURL}
      />
      <Wrapper isCategory={isCategory}>
        <div className="inner-wrapper">
          <header className="header">
            <h1 className="heading">News & Media</h1>
            <article className="flex-row">
              <figure
                className="col col--img"
                onClick={() => {
                  navigate(`/blog/${toSlug(latestBlog.node.data.title.text)}/`);
                }}
              >
                <GatsbyImage
                  image={
                    latestBlog.node.data.featured_image.thumbnails.cropped
                      .gatsbyImageData
                  }
                  alt={latestBlog.node.data.featured_image.alt || "Featured"}
                />
              </figure>
              <div className="col col--text">
                <h2
                  className="title"
                  onClick={() => {
                    navigate(
                      `/blog/${toSlug(latestBlog.node.data.title.text)}/`
                    );
                  }}
                >
                  {latestBlog.node.data.title.text}
                </h2>
                <div className="date-tags">
                  <span>{latestBlog.node.display_date}</span>
                  {latestBlog.node.data.categories.map((item, i) => (
                    <span
                      key={
                        item.category.document
                          ? item.category.document.data.title.text
                          : i
                      }
                    >
                      <span className="pipe-symbol">|</span>
                      {item.category.document &&
                        item.category.document.data.title.text}
                    </span>
                  ))}
                </div>
                <p className="description">
                  {latestBlog.node.data.meta_description
                    ? latestBlog.node.data.meta_description
                    : excerptFallback}
                </p>
                <ButtonUnderlined
                  className="link"
                  label="LEARN MORE"
                  to={`/blog/${toSlug(latestBlog.node.data.title.text)}/`}
                />
              </div>
            </article>
          </header>

          <div id="filters">
            <motion.div
              className="btns btns--category"
              whileTap={{ scale: 0.99 }}
              onClick={toggleCategory}
            >
              <div className="btn" role="button">
                <span className="btn__label">
                  {category || "FILTER BY CATEGORY"}
                </span>
                <span className="btn__arrow">
                  <IconArrowSharpDown />
                </span>
              </div>
              {isCategory && (
                <ul className="option-list">
                  <li
                    className={
                      category === "All" || category === ""
                        ? "option-list__each option-list__each--selected"
                        : "option-list__each"
                    }
                    onClick={() => selectCategory("All")}
                  >
                    All
                  </li>
                  {categories.map((item) => (
                    <li
                      className={
                        category === item
                          ? "option-list__each option-list__each--selected"
                          : "option-list__each"
                      }
                      key={item}
                      onClick={() => selectCategory(item)}
                    >
                      {item}
                    </li>
                  ))}
                  <span className="close-arrow">
                    <IconArrowSharpDown />
                  </span>
                </ul>
              )}
            </motion.div>
          </div>
          <div className="blog-list-subwrapper">
            <FlipMove
              enterAnimation={`fade`}
              leaveAnimation={`fade`}
              className="blog-list"
            >
              {filteredBlogs.map((item) => (
                <article className="blog-list__each" key={item.node.id}>
                  <figure
                    className="featured-img"
                    onClick={() => {
                      navigate(`/blog/${toSlug(item.node.data.title.text)}/`);
                    }}
                  >
                    <GatsbyImage
                      image={
                        item.node.data.featured_image.thumbnails.cropped
                          .gatsbyImageData
                      }
                      alt={item.node.data.featured_image.alt || "Featured"}
                    />
                  </figure>
                  <h3
                    className="title"
                    onClick={() => {
                      navigate(`/blog/${toSlug(item.node.data.title.text)}/`);
                    }}
                  >
                    {item.node.data.title.text}
                  </h3>
                  <div className="date-tags">
                    <span>{item.node.display_date}</span>
                    {item.node.data.categories.map((item, i) => (
                      <span
                        key={
                          item.category.document
                            ? item.category.document.data.title.text
                            : i
                        }
                      >
                        <span className="pipe-symbol">|</span>
                        {item.category.document &&
                          item.category.document.data.title.text}
                      </span>
                    ))}
                  </div>
                  <p className="description">
                    {item.node.data.meta_description
                      ? reduceWords(item.node.data.meta_description, 25)
                      : excerptFallback}
                  </p>
                  <ButtonUnderlined
                    className="link"
                    label="LEARN MORE"
                    to={`/blog/${toSlug(item.node.data.title.text)}/`}
                  />
                </article>
              ))}
            </FlipMove>
            <IconCircleLightPurpleMd className="icon-circle icon-circle--light-purple" />

            <IconCircleDarkPurpleMd className="icon-circle icon-circle--dark-purple" />
            <IconCircleCyanMd className="icon-circle icon-circle--cyan" />
          </div>
        </div>

        <NewsletterForm />
      </Wrapper>
    </Layout>
  );
};

export default BlogsPage;

export const dataQuery = graphql`
  {
    blogs: allPrismicBlog {
      edges {
        node {
          id
          first_publication_date
          data {
            title {
              text
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            meta_description
            edit_published_date
            featured_image {
              alt
              thumbnails {
                cropped {
                  url
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }

    categories: allPrismicCategory {
      edges {
        node {
          id
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
