import React, { useState } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainPurple,
  mainBlack,
  mainCyan,
  mainWhite,
  innerWidth,
  screen,
} from "../variables";
import addToMailchimp from "gatsby-plugin-mailchimp";
import ButtonFilled from "../buttons/button-filled";
import IconSpinner from "../../images/svg/icon-loading.svg";

const Wrapper = styled.div`
  background: ${mainPurple};
  padding: 42px 0 140px 0;
  position: relative;
  margin: 75px 0 0 0;
  @media ${screen.xxsmall} {
    padding: 75px 0;
    margin: 225px 0 0 0;
  }

  .container {
    border-radius: 13px;
    color: ${mainWhite};
    max-width: ${innerWidth};
    width: 100%;

    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      margin: 0 auto;
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    .flex {
      display: flex;
      .col {
        &--form {
          @media ${screen.xxsmall} {
            width: 65%;
            padding: 0 50px 0 0;
          }
          @media ${screen.xsmall} {
            width: 52%;
          }

          .heading {
            font-weight: 900;
            line-height: 1.22;
            max-width: 330px;
            font-size: 1.52rem;
            @media ${screen.xxsmall} {
              font-size: 2.5rem;
            }
            @media ${screen.small} {
              font-size: 3rem;
              line-height: 1.19;
            }
          }

          .description {
            max-width: 470px;
            font-size: 1.04rem;
            margin: 15px 0 26px 0;
            @media ${screen.small} {
              font-size: 1.08rem;
              margin: 15px 0 35px 0;
            }
          }

          .form {
            position: relative;
            z-index: 3;

            .input {
              background: none;
              display: block;
              color: ${mainWhite};
              width: 100%;
              border: 0;
              border-bottom: 2px ${mainWhite} solid;
              font-size: 16px;
              font-weight: 600;
              outline: none;
              border-radius: 0;
              line-height: 2;
              padding: 0 0 2px 0;
              margin: 0 0 22px 0;
              @media ${screen.xxsmall} {
                font-size: 1.1rem;
                width: 50%;
                margin: 0;
                display: inline-block;
              }

              &::placeholder {
                color: ${mainWhite};
                opacity: 0.75;
                font-weight: 400;
              }

              &--fname {
                @media ${screen.xxsmall} {
                  width: calc(50% - 20px);
                  margin: 0 20px 0 0;
                }
              }

              &--email {
                width: 100%;
                margin: 22px 0 32px 0;
                @media ${screen.xxsmall} {
                  margin: 25px 0 35px 0;
                }
              }
            }

            button {
              border: 2px ${mainWhite} solid;
              @media ${screen.withCursor} {
                &:hover {
                  border: 2px ${mainCyan} solid;
                }
              }
            }
          }
        }

        &--img {
          position: relative;
          @media ${screen.xxsmall} {
            width: 35%;
          }
          @media ${screen.xsmall} {
            width: 48%;
          }

          .img-wrapper {
            position: absolute;
            width: 190px;
            z-index: 2;
            bottom: -140px;
            right: -50px;
            @media ${screen.xxsmall} {
              min-width: 250px;
              width: 100%;
              max-width: 650px;
              right: -16px;
              bottom: -75px;
            }
            @media ${screen.xsmall} {
              min-width: 350px;
              right: -8px;
            }
            @media ${screen.small} {
              min-width: 500px;
              right: 16px;
              opacity: 1;
            }
            @media ${screen.medium} {
              min-width: 550px;
            }
            .img-subwrapper {
              .desktop-only {
                display: none;
                @media ${screen.xxsmall} {
                  display: block;
                }
              }
              .mobile-only {
                @media ${screen.xxsmall} {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    #newsletter-section-spinner {
      position: absolute;
      background: rgba(255, 255, 255, 0.975);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;

      .icon {
        svg {
          width: 160px;
          height: 160px;
          @media ${screen.xxsmall} {
            width: 220px;
            height: 220px;
          }
        }
      }

      .msg {
        font-size: 1.16rem;
        text-align: center;
        color: ${mainBlack};
        @media ${screen.xxsmall} {
          font-size: 2rem;
        }
        @media ${screen.xsmall} {
          font-size: 2.36rem;
        }

        .break {
          display: block;
          @media ${screen.xxsmall} {
            display: inline;
          }
        }
      }
    }
  }
`;

const NewsletterForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSucess] = useState(false);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");

  const submitToMailchimp = async () => {
    setIsLoading(true);
    try {
      const result = await addToMailchimp(email, {
        FNAME: fname,
        LNAME: lname,
      });

      if (result) {
        setIsSucess(true);
        setFname("");
        setLname("");
        setEmail("");
        setTimeout(() => {
          setIsLoading(false);
          setIsSucess(false);
        }, 3000);
      }
    } catch (e) {
      alert("Sorry, something went wrong. Please try again later!");
      setIsLoading(false);
      setFname("");
      setLname("");
      setEmail("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    submitToMailchimp();
  };

  return (
    <Wrapper isLoading={isLoading}>
      <div className="container" data-sal="fade" data-sal-duration="700">
        <div className="flex">
          <div className="col col--form">
            <h5 className="heading">Want to stay up to date?</h5>
            <p className="description">
              Sign up to our free quarterly newsletter for fun facts, stories
              and learn more about your dog’s health & safety.
            </p>

            <form onSubmit={handleSubmit} className="form">
              <input
                className="input input--fname"
                type="text"
                placeholder="First Name*"
                autoComplete="off"
                value={fname}
                onChange={(e) => {
                  setFname(e.target.value);
                }}
                required
              />
              <input
                className="input"
                type="text"
                placeholder="Last Name*"
                autoComplete="off"
                value={lname}
                onChange={(e) => {
                  setLname(e.target.value);
                }}
                required
              />
              <input
                className="input input--email"
                type="email"
                placeholder="Email Address*"
                autoComplete="off"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
              <ButtonFilled label="SUBSCRIBE" bgWhite />
            </form>
          </div>

          <div className="col col--img">
            <div className="img-wrapper">
              <div className="img-subwrapper">
                {!isLoading && (
                  <StaticImage
                    src="../../images/K9SWiM-Update-Popup-2.png"
                    alt="K9 Swim Dog Barking"
                    className="desktop-only"
                    placeholder="blurred"
                  />
                )}
                <StaticImage
                  src="../../images/K9SWiM-Update-Popup-1.png"
                  alt="K9 Swim Dog Barking"
                  className="mobile-only"
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </div>

        {isLoading && (
          <div id="newsletter-section-spinner">
            {!isSuccess && (
              <div className="icon">
                <IconSpinner />
              </div>
            )}

            {isSuccess && (
              <p className="msg">
                Thank you, <span className="break">you’re signed up!</span>
              </p>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default NewsletterForm;
